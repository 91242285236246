import React, {
  Fragment,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import {
  MemberCard,
  MembershipPurchase,
  PurchaseItem,
} from "../../../common/payloads";
import { OrderPayload, ShoppingCartObject } from "acme-ticketing-client";
import {
  CustomerInfo,
  InputFieldType,
  KioskPaymentInfo,
} from "../../../common/types";
import { deleteReservation, kioskCheckout } from "../../services/kioskService";
import { performMarketingOptIn } from "../../services/vipService";
import { Form } from "../shared/form/form";
import {
  AccordionStep,
  UPDATE_CART,
  useKiosk,
} from "../../contexts/kioskContext";
import config from "../../config";
import { Checkbox } from "../eventDetail/eventDetailCheckIn/checkbox";
import { KioskButtons } from "./kioskButtons";
import { Input } from "../shared/form/input";
import { useQueryParams } from "../../hooks/useQueryParams";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import { EMAIL_DOMAINS } from "../../constants";
import { KioskModal } from "./kioskModals";

type EventDetailKioskPaymentProps = {
  setOrder: (kioskOrder: OrderPayload & { smsLink: string }) => void;
  onClickNext: () => void;
  onClickBack: () => void;
  reservationId: number;
  optIn: boolean;
  toggleOptIn: () => void;
  handleCancelOrder: () => void;
};

enum InputFields {
  FirstName = 0,
  LastName = 1,
  Email = 2,
  Phone = 3,
  Address = 4,
  City = 5,
  State = 6,
  Zip = 7,
}

enum ActionType {
  UPDATE_FOCUSED_FIELD,
}

type State = {
  focusedField: InputFields;
};

type UpdateFocusedFieldAction = {
  type: ActionType.UPDATE_FOCUSED_FIELD;
  payload: { focusedField: InputFields };
};

type Action = UpdateFocusedFieldAction;

export const EventDetailKioskPayment: React.FC<EventDetailKioskPaymentProps> =
  ({
    setOrder,
    onClickNext,
    onClickBack,
    reservationId,
    optIn,
    toggleOptIn,
    handleCancelOrder,
  }: EventDetailKioskPaymentProps) => {
    const fieldIds = [
      "first-name",
      "last-name",
      "email",
      "phone",
      "address1",
      "city",
      "state",
      "zip",
    ];
    const next = "next ->";
    const continueButton = "continue ->";
    const generateKeyboardDisplay = (text) => {
      return {
        "{enter}": text,
        "{bksp}": "backspace",
        "{tab}": "<- previous",
        "{lock}": "caps lock",
        "{shift}": "shift",
        "{space}": "space",
      };
    };

    const generateKeyboardLayout = () => {
      const layout = {
        default: [
          "` 1 2 3 4 5 6 7 8 9 0 - = {bksp}",
          "{tab} q w e r t y u i o p [ ] \\",
          "{lock} a s d f g h j k l ; ' {enter}",
          "{shift} z x c v b n m , . / {shift}",
          ".com @ {space}",
        ],
        shift: [
          "~ ! @ # $ % ^ &amp; * ( ) _ + {bksp}",
          "{tab} Q W E R T Y U I O P { } |",
          '{lock} A S D F G H J K L : " {enter}',
          "{shift} Z X C V B N M &lt; &gt; ? {shift}",
          ".com @ {space}",
        ],
      };

      layout.default.push(EMAIL_DOMAINS.join(" "));
      layout.shift.push(EMAIL_DOMAINS.join(" "));

      return layout;
    };

    const { creditCardInputs, setShowErrorModal, orderState, orderDispatch } =
      useKiosk();
    const keyboard = useRef();
    // TODO: include address fields in ACME payload or remove from cc inputs

    // Reflect loading state when booking is being processed.
    const [isSearching, setIsSearching] = useState(false);
    const [keyboardDisplay, setKeyboardDisplay] = useState(
      generateKeyboardDisplay(next)
    );
    const [showKeyboard, setShowKeyboard] = useState(false);
    const [shiftActive, setShiftActive] = useState(false);
    const [capsLockActive, setCapsLockActive] = useState(false);

    const [showUpdateTicketsModal, setShowUpdateTicketsModal] = useState(false);

    const reducer = (state: State, action: Action) => {
      switch (action.type) {
        case ActionType.UPDATE_FOCUSED_FIELD:
          return { ...state, focusedField: action.payload.focusedField };
        default:
          console.log("No reducer action defined for", action.type);
          return state;
      }
    };

    const [state, dispatch] = useReducer(reducer, {
      focusedField: InputFields.FirstName,
    });

    /** Add listener to focus on continue button on "Enter" key press */
    useEffect(() => {
      const handleKeydown = (event) => {
        console.log(event);
        if (event.key === "Enter") {
          event.preventDefault();
          document.getElementById("btn-continue").focus();
        }
      };

      window.addEventListener("keydown", handleKeydown);

      return () => window.removeEventListener("keydown", handleKeydown);
    }, []);

    /** set focus on the first name input on load */
    useEffect(() => {
      let firstNameInput = document.getElementById(fieldIds[0]);
      setTimeout(() => {
        firstNameInput.focus();
        setShowKeyboard(true);
      }, 2000);
    }, []);

    const getFocusedCreditCardInput = (field: InputFields): InputFieldType => {
      switch (field) {
        case InputFields.Address:
          return creditCardInputs.fields.address.address1;
        case InputFields.City:
          return creditCardInputs.fields.address.city;
        case InputFields.Email:
          return creditCardInputs.fields.address.email;
        case InputFields.FirstName:
          return creditCardInputs.fields.address.firstName;
        case InputFields.LastName:
          return creditCardInputs.fields.address.lastName;
        case InputFields.Phone:
          return creditCardInputs.fields.address.phoneNumber;
        case InputFields.State:
          return creditCardInputs.fields.address.state;
        case InputFields.Zip:
          return creditCardInputs.fields.address.zipCode;
      }
    };

    const handleSetFocus = (field: InputFields, resetState = true) => {
      if (resetState) {
        dispatch({
          type: ActionType.UPDATE_FOCUSED_FIELD,
          payload: { focusedField: field },
        });
      }

      let inputField = document.getElementById(fieldIds[field]);
      setTimeout(() => {
        inputField.focus();
        // @ts-ignore
        // eslint-disable-next-line prettier/prettier
        keyboard.current && keyboard.current.setInput(getFocusedCreditCardInput(field).value);
      }, 200);
    };

    const onBookClick = async (event?) => {
      event && event.preventDefault();
      setShowKeyboard(false);

      if (creditCardInputs.validatePII(!!orderState.upsell)) {
        const customerInfo: CustomerInfo = {
          contactFirstName: creditCardInputs.values.firstName,
          contactLastName: creditCardInputs.values.lastName,
          email: creditCardInputs.values.email,
          phoneNumber: creditCardInputs.values.phoneNumber,
          address1: creditCardInputs.values.streetAddress1,
          address2: creditCardInputs.values.streetAddress2,
          city: creditCardInputs.values.city,
          state: creditCardInputs.values.state,
          country: "USA",
          zipCode: creditCardInputs.values.zip,
        };

        const primaryCard: MemberCard = {
          cardType: "primary",
          city: creditCardInputs.values.city,
          country: creditCardInputs.values.country,
          email: creditCardInputs.values.email,
          firstName: creditCardInputs.values.firstName,
          lastName: creditCardInputs.values.lastName,
          phoneNumber: creditCardInputs.values.phoneNumber,
          state: creditCardInputs.values.state,
          streetAddress1: creditCardInputs.values.streetAddress1,
          streetAddress2: creditCardInputs.values.streetAddress2,
          zipCode: creditCardInputs.values.zip,
        };
        // TODO: get secondary cardholder info

        const updatedCart: (PurchaseItem | MembershipPurchase)[] =
          orderState.cartItems.map((item: MembershipPurchase) => {
            if (item.itemType === "MembershipPurchase") {
              return {
                ...item,
                membershipInfo: {
                  ...item.membershipInfo,
                  membershipCards: [primaryCard],
                },
              };
            } else {
              return item;
            }
          });

        orderDispatch({
          type: UPDATE_CART,
          payload: { cartItems: updatedCart },
        });
        console.log(updatedCart);
        const shoppingCart: ShoppingCartObject = {
          tenantId: process.env.REACT_APP_ACME_TENANT_ID,
          id: orderState.cartId,
          items: updatedCart,
        };

        const lastFourDigits = creditCardInputs.values.number.slice(-4);
        const paymentInfo: KioskPaymentInfo = {
          expressTransactionId: orderState.expressTransactionId,
          acmeToken: orderState.bin + lastFourDigits,
          ccLastFourDigits: lastFourDigits,
          notes: "#kiosk",
          expDate: creditCardInputs.values.expDate,
          paymentMethods: ["CreditCard"],
          chargeAmount: orderState.orderTotal.toString(),
          terminalId: orderState.terminalId,
        };

        const membershipLevel = orderState.upsell
          ? orderState.upsell.info.name
          : undefined;

        try {
          setIsSearching(true);
          // Submit cart to ACME API
          const checkoutResponse = await kioskCheckout(
            customerInfo,
            shoppingCart,
            paymentInfo,
            reservationId,
            membershipLevel
          );

          if (checkoutResponse.status === 200) {
            const orderResp = checkoutResponse.data as OrderPayload & {
              smsLink: string;
            };
            setOrder(orderResp);
            // Release the reserved tickets
            deleteReservation(reservationId, orderState.cartId);
            // If the user opted into our marketing campaign, we'll notify the Lambda
            if (optIn) {
              performMarketingOptIn(orderResp.orderNumber);
            }
            // Move to next accordion step
            onClickNext();
          } else {
            setShowErrorModal(true);
            setShowKeyboard(true);
          }
        } catch (e) {
          console.log("Error checking out", e);
        } finally {
          setIsSearching(false);
        }
      } else {
        // Display keyboard if PII form input is not valid
        setShowKeyboard(true);
      }
    };

    const onKeyPress = (button) => {
      // Handle if next or continue is pressed
      if (button === "{enter}") {
        let nextField: InputFields;

        if (orderState.upsell || state.focusedField < InputFields.Phone) {
          nextField = state.focusedField + 1;
        } else {
          nextField = InputFields.Zip;
        }

        // Validate the field
        getFocusedCreditCardInput(state.focusedField).attributes.onBlur(null);
        // If guest is on the final input, submit form on continue
        if (state.focusedField === InputFields.Zip) onBookClick();

        // Update keyboard display for zip field
        if (nextField === InputFields.Zip) {
          const display = generateKeyboardDisplay(continueButton);
          setKeyboardDisplay(display);

          keyboard.current &&
            // @ts-ignore
            keyboard.current.setOptions({ display });
        } else {
          const display = generateKeyboardDisplay(next);
          setKeyboardDisplay(display);

          keyboard.current &&
            // @ts-ignore
            keyboard.current.setOptions({ display });
        }

        // Set focus on the next field and clear keyboard input
        handleSetFocus(nextField);
        keyboard.current &&
          // @ts-ignore
          keyboard.current.setInput(getFocusedCreditCardInput(nextField).value);
        console.log(
          "next field value",
          getFocusedCreditCardInput(nextField).value
        );

        // Turn off shift
        setShiftActive(false);

        !capsLockActive &&
          // @ts-ignore
          keyboard.current.setOptions({
            layoutName: "default",
          });
      }
      // Handle if previous is pressed
      else if (button === "{tab}") {
        let prevField: InputFields;

        if (orderState.upsell || state.focusedField < InputFields.Zip) {
          prevField = state.focusedField - 1;
          if (prevField < 0) prevField = 0;
        } else {
          prevField = InputFields.Phone;
        }

        // Validate the field
        getFocusedCreditCardInput(state.focusedField).attributes.onBlur(null);

        // Update keyboard display for zip field
        if (prevField === InputFields.Zip) {
          const display = generateKeyboardDisplay(continueButton);
          setKeyboardDisplay(display);

          keyboard.current &&
            // @ts-ignore
            keyboard.current.setOptions({ display });
        } else {
          const display = generateKeyboardDisplay(next);
          setKeyboardDisplay(display);

          keyboard.current &&
            // @ts-ignore
            keyboard.current.setOptions({ display });
        }

        // Set focus on the next field and clear keyboard input
        handleSetFocus(prevField);
        keyboard.current &&
          // @ts-ignore
          keyboard.current.setInput(getFocusedCreditCardInput(prevField).value);
        console.log(
          "prev field value",
          getFocusedCreditCardInput(prevField).value
        );

        // Turn off shift
        setShiftActive(false);

        !capsLockActive &&
          // @ts-ignore
          keyboard.current.setOptions({
            layoutName: "default",
          });
      }
      // Handle caps lock
      else if (button === "{lock}") {
        // @ts-ignore
        const currentLayout = keyboard.current.options.layoutName;
        const shiftToggle = currentLayout === "default" ? "shift" : "default";
        shiftToggle === "shift"
          ? setCapsLockActive(true)
          : setCapsLockActive(false);

        // @ts-ignore
        keyboard.current.setOptions({
          layoutName: shiftToggle,
        });
      }
      // Handle shift
      else if (button === "{shift}") {
        // @ts-ignore
        const currentLayout = keyboard.current.options.layoutName;
        const shiftToggle = currentLayout === "default" ? "shift" : "default";
        const setShift = !shiftActive && shiftToggle === "shift";

        // @ts-ignore
        keyboard.current.setOptions({
          layoutName: shiftToggle,
        });
        setShiftActive(setShift);
        !setShift && setCapsLockActive(false);
      }
      // Handle all other key presses
      else {
        const field = getFocusedCreditCardInput(state.focusedField);
        let value = field.value;
        // @ts-ignore
        const position = keyboard.current.getCaretPosition();
        let setPosition;
        console.log("Position", position, value.length);

        // Handle when cursor is at end of line
        if (position === value.length) {
          if (button === "{bksp}") {
            value = value.slice(0, -1);
          } else {
            value = value + button;
          }
        }
        // Handle when cursor is in middle of line
        else {
          const beginning = value.slice(0, position);
          const end = value.slice(position);

          if (button === "{bksp}") {
            value = beginning.slice(0, -1) + end;
            setPosition = position > 0 ? position - 1 : 0;
          } else {
            value = beginning + button + end;
            setPosition = position + 1;
          }
        }

        // Update the value of the field
        field.setValue(value);

        // Reset focus on the field
        handleSetFocus(state.focusedField, false);

        // Set caret position
        if (setPosition !== undefined) {
          // @ts-ignore
          keyboard.current.setCaretPosition(setPosition);
          const input = document.getElementById(fieldIds[state.focusedField]);
          // @ts-ignore
          input.setSelectionRange(setPosition, setPosition);
        }

        // Turn off shift
        setShiftActive(false);

        !capsLockActive &&
          // @ts-ignore
          keyboard.current.setOptions({
            layoutName: "default",
          });
      }
    };

    return (
      <Fragment>
        <Form
          header={
            "Please provide the following information to complete your order."
          }
          className="input-form kiosk"
        >
          <Fragment>
            <div className="form__section">
              <Input
                field={{
                  ...creditCardInputs.fields.address.firstName,
                  attributes: {
                    ...creditCardInputs.fields.address.firstName.attributes,
                    onBlur: () => null,
                  },
                }}
                onFocus={() => handleSetFocus(InputFields.FirstName)}
                onClick={() => handleSetFocus(InputFields.FirstName)}
              />
              <Input
                field={{
                  ...creditCardInputs.fields.address.lastName,
                  attributes: {
                    ...creditCardInputs.fields.address.lastName.attributes,
                    onBlur: () => null,
                  },
                }}
                onFocus={() => handleSetFocus(InputFields.LastName)}
                onClick={() => handleSetFocus(InputFields.LastName)}
              />
              <Input
                field={{
                  ...creditCardInputs.fields.address.email,
                  attributes: {
                    ...creditCardInputs.fields.address.email.attributes,
                    onBlur: () => null,
                  },
                }}
                onFocus={() => handleSetFocus(InputFields.Email)}
                onClick={() => handleSetFocus(InputFields.Email)}
              />
              <Input
                field={{
                  ...creditCardInputs.fields.address.phoneNumber,
                  attributes: {
                    ...creditCardInputs.fields.address.phoneNumber.attributes,
                    onBlur: () => null,
                  },
                }}
                onFocus={() => handleSetFocus(InputFields.Phone)}
                onClick={() => handleSetFocus(InputFields.Phone)}
              />

              {/* Address fields are only required for membership upsell orders */}
              {orderState.upsell && (
                <Fragment>
                  <Input
                    field={{
                      ...creditCardInputs.fields.address.address1,
                      attributes: {
                        ...creditCardInputs.fields.address.address1.attributes,
                        onBlur: () => null,
                      },
                    }}
                    onFocus={() => handleSetFocus(InputFields.Address)}
                    onClick={() => handleSetFocus(InputFields.Address)}
                  />
                  <Input
                    field={{
                      ...creditCardInputs.fields.address.city,
                      attributes: {
                        ...creditCardInputs.fields.address.city.attributes,
                        onBlur: () => null,
                      },
                    }}
                    onFocus={() => handleSetFocus(InputFields.City)}
                    onClick={() => handleSetFocus(InputFields.City)}
                  />
                  <Input
                    field={{
                      ...creditCardInputs.fields.address.state,
                      attributes: {
                        ...creditCardInputs.fields.address.state.attributes,
                        onBlur: () => null,
                      },
                    }}
                    onFocus={() => handleSetFocus(InputFields.State)}
                    onClick={() => handleSetFocus(InputFields.State)}
                  />
                </Fragment>
              )}

              <Input
                field={{
                  ...creditCardInputs.fields.address.zipCode,
                  attributes: {
                    ...creditCardInputs.fields.address.zipCode.attributes,
                    onBlur: () => null,
                  },
                }}
                onFocus={() => handleSetFocus(InputFields.Zip)}
                onClick={() => handleSetFocus(InputFields.Zip)}
              />
            </div>

            {config.smsOptInUrl ? (
              <Checkbox
                id="sms-opt-in-checkbox"
                value="sms-opt-in-checkbox"
                checked={optIn}
                onChange={toggleOptIn}
                label="Sign me up to receive special offers via SMS"
              />
            ) : null}
          </Fragment>
        </Form>
        <div className="kiosk__disclaimer">
          <h4>
            Tickets are non-refundable. We are committed to providing the best
            possible on-site experience at the Barnes. Please visit our website
            for current health and safety guidelines.
          </h4>
        </div>
        <KioskButtons
          handleContinue={onBookClick}
          onClickBack={() => setShowUpdateTicketsModal(true)}
          isLoaded={!isSearching}
          continueText="Book Tickets"
          backText="Update Tickets"
          disabled={isSearching}
          handleReset={handleCancelOrder}
        />

        {showKeyboard && (
          <div className="kiosk__keyboard">
            <Keyboard
              keyboardRef={(r) => (keyboard.current = r)}
              onKeyPress={onKeyPress}
              display={keyboardDisplay}
              mergeDisplay={true}
              newLineOnEnter={false}
              tabCharOnTab={false}
              layout={generateKeyboardLayout()}
            />
          </div>
        )}

        {/* Modal for Update Tickets message */}
        {showUpdateTicketsModal && (
          <KioskModal
            header="Update your tickets"
            body="Going back to update your ticket type or quantity will cancel your previous credit card transaction."
            onContinue={onClickBack}
            continueText="Continue to update tickets"
            onBack={() => setShowUpdateTicketsModal(false)}
            backText="Back to payment"
            dismissable={true}
          />
        )}
      </Fragment>
    );
  };
